import gql from 'graphql-tag';

const userFragments = {
  fullUser: gql`
    fragment FullUser on User {
      id
      firstName
      lastName
      fullName
      email
      language
      type
      dateCreated
      stripeCustomerId
      stripePaymentMethodId
      confirmed
      currency
      products {
        id
      }
      activeSubscriptions
      expiredSubscriptions
      inTheRed
    }
  `
};

export default userFragments;
