import { formatURLTitle } from './http.js';
import moment from 'moment';

const buildInternalSubscriptionUrl = (subscription) => {
  // check if they are subscribed, if they are just send em to the article page
  // but don't include an article id so they just get the latest article they have
  // subscribed to
  if (subscription.subscribed || subscription.expired) {
    return buildInternalArticleUrl(null, subscription);
  }
  // subscriptionId/:subscriptionTitle
  return `/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}`;
};

const buildInternalArticleUrl = (article, subscription) => {
  if (article) {
    // subscriptionId/:subscriptionTitle/articles/:articlePublicId/:articleTitle
    return `/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}/articles/${article.publicId}/${formatURLTitle(article.title)}`;
  }
  // subscriptionId/:subscriptionTitle/articles
  return `/subscriptions/${subscription.id}/${formatURLTitle(subscription.title)}/articles`;
};

const isActiveSubscription = (user, subscriptionId) => {
  let isActive = false;
  if (user.activeSubscriptions && user.activeSubscriptions.length > 0) {
    const activeSubscriptionId = user.activeSubscriptions.find(as => as === subscriptionId);
    isActive = activeSubscriptionId !== undefined;
  }
  return isActive;
};

const isExpiredSubscription = (user, subscriptionId) => {
  let hasExpired = false;
  if (user.expiredSubscriptions && user.expiredSubscriptions.length > 0) {
    const expiredSubscriptionId = user.expiredSubscriptions.find(es => es === subscriptionId);
    hasExpired = expiredSubscriptionId !== undefined;
  }
  return hasExpired;
};

const hasHadAnySubscriptions = (user) => {
  if ((user.activeSubscriptions && user.activeSubscriptions.length > 0) ||
    (user.expiredSubscriptions && user.expiredSubscriptions.length > 0)
  ) {
    return true;
  }
  return false;
};

const hasActiveSubscription = (user) => {
  if (user.activeSubscriptions && user.activeSubscriptions.length > 0) {
    return true;
  }
  return false;
};

const getPlanByType = (subscription, planType) => {
  const plan = { ...subscription.subscriptionPlans.find(plan => plan.type.toLowerCase() === planType) };
  plan.subscription = subscription;
  return plan;
};

const subscriptionContainsPublishedArticle = (subscription) => {
  let containsPublishedArticle = false;
  if (subscription.articles) {
    const publicArticles = subscription.articles.filter(article => article.status === 'Published');
    containsPublishedArticle = publicArticles && publicArticles.length > 0;
  }
  return containsPublishedArticle;
};

// check to see if all hands attached to an article are free, only then can an article be marked as free
const allHandsAreFree = (hands) => {
  let allFreeHands = false;
  if (hands && hands.length > 0) {
    const notFreeHands = hands.filter(h => !h.freeToPlay);
    allFreeHands = notFreeHands.length === 0;
  }
  return allFreeHands;
};

const getArticlesForMonth = (articles, month, year) => {
  const articleForMonth = articles.filter(a => moment(a.publishDateTime).month() === month && moment(a.publishDateTime).year() === year);
  return articleForMonth.map(a => moment(a.publishDateTime).date());
};

const getArticleByDate = (articles, date) => {
  // date should be in ISO format
  const justDate = moment(date, 'YYYY-MM-DD');
  const foundArticle = articles.find(a => {
    const justArticleDate = moment(a.publishDateTime, 'YYYY-MM-DD');
    if (justDate.isSame(justArticleDate)) {
      return a;
    }
    return null;
  });
  return foundArticle;
};

export {
  buildInternalSubscriptionUrl,
  buildInternalArticleUrl,
  isActiveSubscription,
  isExpiredSubscription,
  hasHadAnySubscriptions,
  hasActiveSubscription,
  getPlanByType,
  subscriptionContainsPublishedArticle,
  allHandsAreFree,
  getArticlesForMonth,
  getArticleByDate
};
