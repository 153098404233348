import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useMatches, Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Alert,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  useMediaQuery
} from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import BBTranslate from '../../Locale/BBTranslate.js';
// import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Nav from './Nav.js';
import Logo from './Logo.js';
import SearchBar from './SearchBar';
import Account from './Account.js';
import LanguageSelector from '../../Locale/LanguageSelector.js';
import { CartContext, DrawerContext, UserContext } from '../../../Context';

const useStyles = makeStyles((theme, props) => {
  return ({
    appBar: (props) => ({
      background: props.transparent ? 'transparent' : '#fff',
      position: props.transparent ? 'absolute' : 'relative',
      height: '14rem',
      zIndex: 100,
      [theme.breakpoints.down('md')]: {
        height: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        background: '#fff'
      }
    }),
    container: {
      width: '100%',
      maxWidth: '118rem',
      margin: '0 auto',
      padding: '3.5rem 1rem',
      [theme.breakpoints.down('md')]: {
        padding: '1rem'
      }
    },
    toolbar: {
      padding: 0
    },
    grow: {
      flexGrow: 1
    },
    nav: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    searchBar: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        width: '30ch'
      },
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    iconMenu: {
      color: theme.palette.styleguide.maroon
    },
    hamburgerMenu: {
      float: 'right',
      padding: '4px',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    hamburgerMenuButton: {
      color: 'inherit'
    },
    languageAndAccount: {
      display: 'inline',
      [theme.breakpoints.down(500)]: {
        display: 'none'
      }
    },
    link: {
      color: '#fff'
    }
  });
});

const AdmiralAppBar = ({ transparent }) => {
  const classes = useStyles({ transparent });
  const { cart } = useContext(CartContext);
  const { openDrawer, closeDrawer } = useContext(DrawerContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const matches = useMatches();
  const theme = useTheme();

  const useSmallLogo = useMediaQuery(theme.breakpoints.down('lg'));

  const tabs = [
    { label: 'products', path: '/products' },
    { label: 'subscriptions', path: '/subscriptions' },
    { label: 'authors', path: '/authors' }
  ];

  const accountTabs = [];

  if (!user) {
    tabs.push({ label: 'login', drawer: 'login' });
  } else {
    tabs.push({ label: 'my-library', path: '/library' });

    if (user.type === 'Admin') {
      accountTabs.push({
        label: 'Admin area',
        path: '/admin',
        IconComponent: AdminPanelSettingsOutlinedIcon
      });
    } else if (user.type === 'Publisher') {
      accountTabs.push({
        label: 'My content',
        path: '/admin',
        IconComponent: AdminPanelSettingsOutlinedIcon
      });
    } else if (user.type === 'Editor') {
      accountTabs.push({
        label: 'Edit content',
        path: '/admin',
        IconComponent: AdminPanelSettingsOutlinedIcon
      });
    }

    accountTabs.push({
      label: <BBTranslate textId='my-account' />,
      path: '/account',
      IconComponent: AccountCircleOutlinedIcon
    });

    accountTabs.push({
      label: <BBTranslate textId='help' />,
      path: '/help',
      IconComponent: InfoOutlinedIcon
    });

    accountTabs.push({
      label: <BBTranslate textId='logout' />,
      path: '/logout',
      IconComponent: LogoutIcon
    });
  }

  // set active tab from browser path
  const activeNavTab = tabs.findIndex(tab => {
    return matches.find(match => match.pathname === tab.path);
  });

  const handleCartClick = () => {
    openDrawer('cart');
  };
  const handleTabClick = (tab, tabIndex) => {
    if (tab.path) {
      // the tab clicked should handle navigation away
      closeDrawer();
    } else if (tab.drawer) {
      openDrawer(tab.drawer);
    }
  };
  const handleHamburgerMenuClick = () => {
    openDrawer('menu',
      {
        tabs,
        accountTabs,
        onSearchSubmit: handleSearchSubmit,
        onTabClick: handleTabClick
      });
  };

  const handleSearchSubmit = (searchTerm) => {
    closeDrawer();
    navigate(`/search?search=${searchTerm}`);
  };

  return (
    <>
      {
        user && user.inTheRed && (
          <Alert variant='filled' severity='error' sx={{ justifyContent: 'center' }}>
            <BBTranslate textId='there-was-a-prolem-taking-payment' />
            .&nbsp;&nbsp;
            <Link to='/account/payment' className={classes.link}>
              <BBTranslate textId='please-update-your-payment-method' />
            </Link>
          </Alert>
        )
      }
      <AppBar className={classes.appBar} position='relative'>
        <div className={classes.container}>
          <Toolbar className={classes.toolbar}>
            <Logo
              iconOnly={useSmallLogo}
            />
            <div className={classes.grow} />
            <div className={classes.nav}>
              <Nav
                activeNavTab={activeNavTab}
                tabs={tabs}
                onNavClick={handleTabClick}
              />
            </div>
            <div className={classes.searchBar}>
              <SearchBar
                background={transparent ? '#fff' : undefined}
                onSearchSubmit={handleSearchSubmit}
              />
            </div>
            <div className={classes.iconMenu}>
              {
                // <IconButton aria-label='show 4 new mails' color='inherit' size='large'>
                //   <FavoriteBorderOutlinedIcon fontSize='small' />
                // </IconButton>
              }
              <IconButton onClick={handleCartClick} aria-label='cart' color='inherit' size='large'>
                <Badge
                  badgeContent={cart && cart.items ? cart.items.length : 0}
                  color='secondary'
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <LocalMallOutlinedIcon fontSize='small' />
                </Badge>
              </IconButton>
              <LanguageSelector />
              <div className={classes.languageAndAccount}>
                {accountTabs.length > 0 && (
                  <Account
                    onMenuClick={handleTabClick}
                    accountTabs={accountTabs}
                  />
                )}
              </div>
              <div className={classes.hamburgerMenu}>
                <IconButton
                  onClick={handleHamburgerMenuClick}
                  aria-label='open menu'
                  className={classes.hamburgerMenuButton}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </div>
          </Toolbar>
        </div>
      </AppBar>
    </>
  );
};

AdmiralAppBar.propTypes = {
  transparent: PropTypes.bool
};

export default AdmiralAppBar;
