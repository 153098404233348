import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

const styles = {
  grid: {
    width: '100%',
    justifyContent: 'flex-start'
  }
};

const BBDatePicker = (props) => {
  const { classes, label, date, onChange } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid container className={classes.grid}>
        <Grid item>
          <DatePicker
            autoOk
            disableToolbar
            slotProps={{ textField: { variant: 'standard' } }}
            margin='normal'
            label={label}
            value={date}
            onChange={onChange}
            format='D MMMM YYYY'
          />
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

BBDatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  date: PropTypes.object
};

export default withStyles(styles)(BBDatePicker);
