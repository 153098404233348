import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ArticleIcon from '@mui/icons-material/Article';
import {
  Badge,
  Tooltip
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import {
  getArticleByDate,
  getArticlesForMonth
} from '../../../../../helpers/subscription';

function ArticleDay (props) {
  const { articleDays = [], day, articles, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && articleDays.indexOf(props.day.date()) >= 0;

  const article = getArticleByDate(articles, day.toISOString());

  if (!article) {
    return <PickersDay {...other} disabled day={day} />;
  }

  return (
    <Tooltip
      placement='top-end'
      title='Article title'
      arrow
    >
      <Badge
        key={props.day.toString()}
        overlap='circular'
        badgeContent={
          isSelected
            ? <ArticleIcon color='success' fontSize='small' sx={{ height: '.7em' }} />
            : undefined
          }
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>
    </Tooltip>
  );
}

export default function ArticleCalendar ({ availableArticles, onArticleClick }) {
  const [articleDays, setArticleDays] = useState(null);
  const [selectedDayValue, setSelectedDayValue] = useState(null);

  useEffect(() => {
    const articlesForMonth = getArticlesForMonth(
      availableArticles,
      moment().month(),
      moment().year()
    );
    setArticleDays(articlesForMonth);
  }, [availableArticles, setArticleDays]);

  const handleDayChange = (value) => {
    setSelectedDayValue(value);
    const article = getArticleByDate(availableArticles, value);
    if (article) {
      onArticleClick({
        publicId: article.publicId,
        title: article.title
      });
    }
  };

  const handleMonthChange = (date) => {
    // getArticlesForMonth...
    const articlesForMonth = getArticlesForMonth(
      availableArticles,
      moment(date).month(),
      moment(date).year()
    );
    setArticleDays(articlesForMonth);
  };

  if (!articleDays) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateCalendar
        value={selectedDayValue}
        onChange={handleDayChange}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ArticleDay,
          articles: availableArticles
        }}
        slotProps={{
          day: {
            articleDays,
            articles: availableArticles,
            badgers: 'yes badgers'
          }
        }}
      />
    </LocalizationProvider>
  );
}
