import React, { useState, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useQuery, useMutation } from '@apollo/client';
import { mutations } from '../../../../graphql';
import {
  paymentQueries
} from '../../../../graphql/queries';
import { makeStyles } from '@mui/styles';
// import DeleteIcon from '@mui/icons-material/Delete';
import DeleteIcon from '@mui/icons-material/DeleteOutline.js';
import {
  Alert,
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Tooltip
} from '@mui/material';
import StoreButton from '../../../../Components/Forms/Store/StoreButton.js';
import CardForm from './CardForm.js';
import CurrencyForm from './CurrencyForm.js';
import BBTranslate from '../../../../Components/Locale/BBTranslate.js';
import { useScreenDimensions } from '../../../../Hooks';
import { LocaleContext, UserContext } from '../../../../Context';
import { createPaymentMethod } from '../../../../helpers/checkout.js';
import {
  hasHadAnySubscriptions,
  hasActiveSubscription
} from '../../../../helpers/subscription.js';
import SavingBackdrop from '../../../../Components/Forms/SavingBackdrop.js';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderWidth: '0.05rem 0 0 0',
    margin: '6rem 0'
  },
  table: {
    margin: '3rem 0',
    '& tr': {
      '&:last-child': {
        '& td': {
          border: 'none'
        }
      }
    }
  },
  card: {
    width: '5rem',
    textAlign: 'right',
    pointerEvents: 'none',
    '& img': {
      width: 50,
      height: 30
    }
  },
  delete: {
    color: theme.palette.styleguide.maroon
  }
}));

const PaymentMethod = (props) => {
  const {
    paymentMethod,
    defaultMethod,
    isDefaultLoading,
    isDeleteLoading,
    onDelete,
    onMakeDefault,
    classes
  } = props;
  const { id, last4, cardType } = paymentMethod;

  const { width } = useScreenDimensions();

  const handleDelete = () => {
    onDelete(id);
  };
  const handleMakeDefault = () => {
    onMakeDefault(id);
  };
  return (
    <>
      <TableCell>
        <Box className={classes.card}>
          <img src={`/img/components/card_details/icon_${cardType}.png`} alt={cardType} />
        </Box>
      </TableCell>
      <TableCell>
        {
          width > 500 &&
            <>*** *** *** </>
        }
        {last4}
      </TableCell>
      <TableCell>
        {
          defaultMethod
            ? <span>[Default]</span>
            : (
              <StoreButton
                color='none'
                variant='outlined'
                disabled={isDefaultLoading}
                onClick={handleMakeDefault}
                label='make-default'
              />
              )
        }
      </TableCell>
      <TableCell>
        <Tooltip
          title='Delete payment method'
          placement='bottom-start'
          enterDelay={100}
        >
          <IconButton
            disabled={isDeleteLoading}
            onClick={handleDelete}
          >
            <DeleteIcon className={classes.delete} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );
};

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    handleSaveUser,
    handleSubmitError,
    handleSubmitSuccess,
    handleWarningMessage,
    isLoading
  } = useOutletContext();

  const classes = useStyles();

  const { setCurrency } = useContext(LocaleContext);
  const { user, setUser } = useContext(UserContext);

  const [cardFormOpen, setCardFormOpen] = useState(false);
  const [isCardFormLoading, setCardFormLoading] = useState(false);
  const [cardFormError, setCardFormError] = useState('');
  const [cardFormIsValid, setCardFormIsValid] = useState(false);

  const [isDefaultLoading, setDefaultLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const [attachPaymentMethod] = useMutation(mutations.AttachPaymentMethod);
  const [detachPaymentMethod] = useMutation(mutations.DetachPaymentMethod);
  const [setDefaultPaymentMethod] = useMutation(mutations.SetDefaultPaymentMethod);

  const { data: paymentMethods, refetch } = useQuery(paymentQueries.PaymentMethodsByCustomerId, {
    variables: {
      stripeCustomerId: user.stripeCustomerId
    },
    fetchPolicy: 'no-cache',
    onError: (err) => {
      console.log('getProducts err', err);
    }
  });

  const handleCardFormError = (err) => {
    // handle errors locally rather than using snackbars
    setCardFormError(err);
    setCardFormIsValid(false);
  };

  const handleCardDetailsComplete = (isFormComplete) => {
    setCardFormIsValid(isFormComplete);
  };

  const handleCardFormSubmit = async () => {
    setCardFormLoading(true);
    setCardFormError(null);

    const paymentElement = elements.getElement('cardNumber');

    try {
      const paymentMethodId = await createPaymentMethod(
        stripe,
        paymentElement,
        'card',
        user,
        true
      );
      await attachPaymentMethod({
        variables: {
          id: paymentMethodId,
          stripeCustomerId: user.stripeCustomerId
        }
      });
      if (!user.stripePaymentMethodId) {
        await handleMakeDefault(paymentMethodId);
      }
      await refetch();
      setCardFormOpen(false);
      handleSubmitSuccess('Card added');
    } catch (err) {
      handleCardFormError(err);
    } finally {
      setCardFormLoading(false);
    }
  };

  const handlePaymentMethodDelete = async (id) => {
    // if the customer has an active subscription then we need to make sure they always have a
    // valid (default) card attached so we can bill it in the future, so if this is their only card
    // don't allow them to delete it
    if (id === user.stripePaymentMethodId && hasActiveSubscription(user)) {
      handleWarningMessage('You cannot delete your default card as you have an active subscription that is using it. To delete this card please make another card default first');
    } else {
      setDeleteLoading(true);
      await detachPaymentMethod({
        variables: { id }
      });
      if (user.stripePaymentMethodId === id) {
        await handleSaveUser({ ...user, stripePaymentMethodId: null });
      }
      refetch();
      setDeleteLoading(false);
      handleSubmitSuccess('Card deleted');
    }
  };

  const handleMakeDefault = async (paymentMethodId) => {
    setDefaultLoading(true);
    await handleSaveUser({
      ...user,
      stripePaymentMethodId: paymentMethodId
    });
    await setDefaultPaymentMethod({
      variables: {
        id: paymentMethodId,
        stripeCustomerId: user.stripeCustomerId,
        userId: user.id
      }
    });
    setUser({
      ...user,
      inTheRed: false,
      stripePaymentMethodId: paymentMethodId
    });
    setDefaultLoading(false);
  };

  const handleAddCardClick = () => {
    setCardFormOpen(true);
  };

  const handleCardFormClose = () => {
    setCardFormOpen(false);
  };

  const handleCurrencyChange = async (currency) => {
    setCurrency(currency);
  };

  if (!paymentMethods) {
    return (
      <SavingBackdrop open />
    );
  }

  // once someone has subscribed to a subscription they have their currency set in stripe (which can't be changed)
  // so we need to disallow currency changes for all those who have ever subscribed
  const showCurrency = !hasHadAnySubscriptions(user);

  return (
    <Box sx={{ maxWidth: '58rem', marginLeft: 'auto', marginRight: 'auto' }}>
      <Typography variant='h4'>
        <BBTranslate textId='payment-methods' />
      </Typography>
      <Typography variant='body3'>
        <BBTranslate textId='manage-your-saved-card' />
      </Typography>
      {
        user.inTheRed && (
          <Alert variant='outlined' severity='error' sx={{ marginTop: 3 }}>
            <BBTranslate textId='you-need-to-update-your-card' />
          </Alert>
        )
      }
      {
        paymentMethods.paymentMethodsByCustomerId.length === 0
          ? (
            <Typography variant='body1' sx={{ margin: '2rem 0' }}>
              <BBTranslate textId='no-payment-methods' />
            </Typography>
            )
          : (
            <Table className={classes.table}>
              {
                paymentMethods.paymentMethodsByCustomerId.map((pm, index) => (
                  <TableRow key={index} className={classes.row}>
                    <PaymentMethod
                      isDeleteLoading={isDeleteLoading}
                      isDefaultLoading={isDefaultLoading}
                      defaultMethod={user.stripePaymentMethodId === pm.id}
                      paymentMethod={pm}
                      onDelete={handlePaymentMethodDelete}
                      onMakeDefault={handleMakeDefault}
                      classes={classes}
                    />
                  </TableRow>
                ))
              }
            </Table>
            )
      }
      <Box sx={{ textAlign: 'right' }}>
        <StoreButton
          label='add-new-card'
          onClick={handleAddCardClick}
        />
      </Box>
      {
        showCurrency && (
          <>
            <hr className={classes.divider} />
            <Typography variant='h4'>
              <BBTranslate textId='currency' />
            </Typography>
            <Typography variant='body3'>
              <BBTranslate textId='select-your-preferred-currency' />
            </Typography>
            <CurrencyForm
              user={user}
              isLoading={isLoading}
              onSubmit={handleCurrencyChange}
              onSubmitSuccess={handleSubmitSuccess}
              onSubmitError={handleSubmitError}
            />
          </>
        )
      }
      <CardForm
        user={user}
        isLoading={isCardFormLoading}
        isValid={cardFormIsValid}
        open={cardFormOpen}
        error={cardFormError}
        onFormComplete={handleCardDetailsComplete}
        onFormError={handleCardFormError}
        onSubmit={handleCardFormSubmit}
        onModalClose={handleCardFormClose}
      />
    </Box>
  );
};

Payment.propTypes = {
};

export default Payment;
